import { createRouter, createWebHashHistory } from 'vue-router';
import { notification } from 'ant-design-vue';
import NProgress from 'nprogress'; // 引入进度条插件
import 'nprogress/nprogress.css'; // 引入对应css样式
import { siteConfig } from '@/config/default.config';
import { hasPerm } from '@/utils/util';
import { getRefreshToken } from '@/utils/auth';
import { getAllRoutes } from '@/router/routes';
import routes from './routes';
import routesMap from './map';
import { useUserStore } from '@/store/user';
import { refreshToken } from '@/apis/login';

const whiteList = ['/login', '/404'];

NProgress.configure({
  showSpinner: false,
});

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return false;
  },
});

router.beforeEach(async (to, from, next) => {
  const user = useUserStore();
  console.log('beforeEach');
  NProgress.start();
  if (router.options.isAddDynamicRoutes || whiteList.indexOf(to.path) >= 0) {
    next();
    return;
  }
  if (to.meta.isAuth == false) {
    next();
    return;
  }
  try {
    const refresh_token = getRefreshToken();
    const res = await refreshToken({ refresh_token });
    const routes = await getAllRoutes();
    if (!res.access_token) {
      next(`/login?redirect=${to.path}`);
      return;
    }
    user.setUserinfo(res);
    const permissions = res.permissions || [];
    if (permissions.length > 0) {
      console.log('%c<------ 新增动态路由 begin ------>', 'color:orange');
      let menuList = [];
      routes.forEach(item => {
        menuList = menuList.concat(item);
      });
      fnAddDynamicRoutes(menuList);
      console.log('%c<------ 新增动态路由 end ------>', 'color:orange');
    }
    router.options.isAddDynamicRoutes = true;
    if (to.path === '/login') {
      next('/login');
      NProgress.done();
    } else {
      next({
        path: to.path,
        query: to.query,
      });
      NProgress.done();
    }
  } catch (err) {
    next(`/login?redirect=${to.path}`);
    NProgress.done();
  }
});

router.afterEach((to, from) => {
  console.log('afterEach');
  document.title = `${siteConfig.title} - ${to.meta.title as string}`;
  if (to.path === '/login') {
    // 退出登录后，重置设置isAddDynamicRoutes为false
    router.options.isAddDynamicRoutes = false;
  }
  NProgress.done();
});

router.onError((error) => {
  console.log('路由报错', error.message);
  // Error: Loading chunk preStowage failed. (/css/preStowage.c5f746b4.css) at HTMLLinkElement.linkTag.onerror
  // Error: Loading css chunk preStowage failed. (/css/preStowage.c5f746b4.css) at HTMLLinkElement.linkTag.onerror
  // ERROR: Unable to preload CSS for /css/claims.00441be4.1226_1045.css
  const pattern = /Loading(.*?)chunk(.*?)failed/g;
  const pattern2 = /Unable to preload CSS for/g;
  const pattern3 = /Failed to fetch dynamically imported module/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const isChunkLoadFailed2 = error.message.match(pattern2);
  const isChunkLoadFailed3 = error.message.match(pattern3);
  console.log('isChunkLoadFailed', isChunkLoadFailed);
  console.log('isChunkLoadFailed2', isChunkLoadFailed2);
  console.log('isChunkLoadFailed3', isChunkLoadFailed3);
  if (isChunkLoadFailed || isChunkLoadFailed2 || isChunkLoadFailed3) {
    // 当前页面有更新，请刷新页面重新进入
    notification.warning({
      message: '提示',
      description: '当前页面有更新，请刷新页面重新进入',
    });
  } else if (error.message.indexOf('Cannot find module') >= 0) {
    // 没有找到页面，请刷新页面重试
    notification.warning({
      message: '提示',
      description: '没有找到页面，请刷新页面重试',
    });
  }
});

/**
 * 添加动态路由
 * @param {*} menuList 菜单列表
 */
function fnAddDynamicRoutes(menuList: MenuType[]) {
  const perms = (localStorage.getItem('perms') || '').split(',');
  for (var i = 0; i < menuList.length; i++) {
    const data = menuList[i];
    var route = {
      name: data.code,
      path: data.path,
      parentName: data.parentName,
      meta: {
        isDynamic: true, // 标识为动态路由
        icon: data.icon,
        title: data.title,
      },
      component: null,
      children: [],
    };
    if (data.path && data.path && /\S/.test(data.path)) {
      route.path = data.path;
    }
    try {
      route.component = routesMap[data.path] || null;
      if (hasPerm(data)) {
        if (!router.hasRoute(route.name)) {
          router.addRoute(route.parentName || '', route);
        }
      } else {
        router.removeRoute(route.name);
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default router;