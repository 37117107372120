export const fencePerm = '围栏管理';
export const fenceSendPerm = 'WEB电子围栏';
export const fenceCollectorsPerm = 'WEB揽收员负责区域';
export const fenceCollectorPddPerm = '拼多多揽收区域';
export const fenceCollectorBpPerm = '奔跑揽收区域';
export const fenceCollectorSantaiPerm = '义乌三态揽收区域';
export const fenceCollectorAnshunPerm = '安顺揽收区域';
export const fenceCollectorRefresh = '刷新订单揽收员';
// export const printPerm = 'WEB打印模板';

export const orderPerm = '订单相关';
export const orderVolumnPerm = '货物体积';
export const orderCancelPerm = '订单取消';
export const orderPickPerm = '订单提货';
export const orderPCPerm = 'PC码查询';
export const orderPushPerm = '推送查询'

export const controlPerm = '配置管理';
export const controlDeptPerm = '网点配置';
export const controlWechatPerm = '微信配置';

export const appPerm = 'APP_CONFIG'; // APP配置
export const appMenuBasePerm = 'APP_MENU_BASE'; // APP基础菜单
export const appMenuBaseBtns = {
  add: 'APP_MENU_BASE_ADD', // 新增
  copy: 'APP_MENU_BASE_COPY', // 复制
  update: 'APP_MENU_BASE_UPDATE', // 修改
  remove: 'APP_MENU_BASE_DELETE', // 删除
}
export const appMenuDeptPerm = 'APP_MENU_DEPT'; // APP网点配置菜单
export const appMenuDeptBtns = {
  add: 'APP_MENU_DEPT_ADD', // 新增
  copy: 'APP_MENU_DEPT_COPY', // 复制
  update: 'APP_MENU_DEPT_UPDATE', // 修改
  remove: 'APP_MENU_DEPT_DELETE', // 删除
}
export const appPrintTplPerm = 'APP_PRINT_TPL'; // APP相关
export const appPrintTplBtns = {
  add: 'APP_PRINT_TPL_ADD', // 新增
  copy: 'APP_PRINT_TPL_COPY', // 复制
  update: 'APP_PRINT_TPL_UPDATE', // 修改
  remove: 'APP_PRINT_TPL_DELETE', // 删除
}


export const userPerm = '账号管理';
export const userPddPerm = 'USER_PDD'; // 拼多多账号管理
export const userBpPerm = 'USER_BP'; // 奔跑账号管理
export const userPddBtns = {
  add: 'USER_PDD_ADD', // 新增
  addBp: 'USER_PDD_ADD_BP', // 新增奔跑账号
  updateName: 'USER_PDD_UPDATE_NAME', // 修改姓名
  setPwd: 'USER_PDD_SET_PWD', // 设置密码
  enable: 'USER_PDD_ENABLE', // 启用
  stop: 'USER_PDD_STOP', // 停用
}

export const financePerm = 'FINANCE';
export const financeBasicPerm = 'FINANCE_BASIC'; // 财务基础档案
export const financeBasicBtns = {
  add: 'FINANCE_BASIC_ADD', // 新增
  update: 'FINANCE_BASIC_UPDATE', // 修改
  remove: 'FINANCE_BASIC_REMOVE', // 删除
}

export const financeDepreciatePerm = 'FINANCE_DEPRECIATE'; // 资产折旧
export const financeDepreciateBtns = {
  add: 'FINANCE_DEPRECIATE_ADD', // 新增
  update: 'FINANCE_DEPRECIATE_UPDATE', // 修改
  remove: 'FINANCE_DEPRECIATE_REMOVE', // 删除
  audit: 'FINANCE_DEPRECIATE_AUDIT', // 审核
  adjust: 'FINANCE_DEPRECIATE_ADJUST', // 调整
  rate: 'FINANCE_DEPRECIATE_RATE', // 折旧
  rateRecord: 'FINANCE_DEPRECIATE_RATE_RECORD', // 折旧记录
  end: 'FINANCE_DEPRECIATE_END', // 终止
  log: 'FINANCE_DEPRECIATE_LOG', // 日志
  import: 'FINANCE_DEPRECIATE_IMPORT', // 导入
  export: 'FINANCE_DEPRECIATE_EXPORT', // 导出
  template: 'FINANCE_DEPRECIATE_TEMPLATE', // 模板
}
export const financeLongtermPerm = 'FINANCE_LONGTERM'; // 长期待摊
export const financeLongtermBtns = {
  add: 'FINANCE_LONGTERM_ADD', // 新增
  update: 'FINANCE_LONGTERM_UPDATE', // 修改
  remove: 'FINANCE_LONGTERM_REMOVE', // 删除
  audit: 'FINANCE_LONGTERM_AUDIT', // 审核
  adjust: 'FINANCE_LONGTERM_ADJUST', // 调整
  amortize: 'FINANCE_LONGTERM_AMORTIZE', // 摊销
  amortizeRecord: 'FINANCE_LONGTERM_AMORTIZE_RECORD', // 摊销记录
  end: 'FINANCE_LONGTERM_END', // 终止
  log: 'FINANCE_LONGTERM_LOG', // 日志
  import: 'FINANCE_LONGTERM_IMPORT', // 导入
  export: 'FINANCE_LONGTERM_EXPORT', // 导出
  template: 'FINANCE_LONGTERM_TEMPLATE', // 模板
}
export const financeReceivablePerm = 'FINANCE_RECEIVABLE'; // 应收租金
export const financeReceivableBtns = {
  add: 'FINANCE_RECEIVABLE_ADD', // 新增
  update: 'FINANCE_RECEIVABLE_UPDATE', // 修改
  remove: 'FINANCE_RECEIVABLE_REMOVE', // 删除
  audit: 'FINANCE_RECEIVABLE_AUDIT', // 审核
  adjust: 'FINANCE_RECEIVABLE_ADJUST', // 调整
  amortize: 'FINANCE_RECEIVABLE_AMORTIZE', // 摊销
  amortizeRecord: 'FINANCE_RECEIVABLE_AMORTIZE_RECORD', // 摊销记录
  log: 'FINANCE_RECEIVABLE_LOG', // 日志
  export: 'FINANCE_RECEIVABLE_EXPORT', // 导出
}
export const financePayablePerm = 'FINANCE_PAYABLE'; // 应付租金
export const financePayableBtns = {
  add: 'FINANCE_PAYABLE_ADD', // 新增
  update: 'FINANCE_PAYABLE_UPDATE', // 修改
  remove: 'FINANCE_PAYABLE_REMOVE', // 删除
  audit: 'FINANCE_PAYABLE_AUDIT', // 审核
  adjust: 'FINANCE_PAYABLE_ADJUST', // 调整
  amortize: 'FINANCE_PAYABLE_AMORTIZE', // 摊销
  amortizeRecord: 'FINANCE_PAYABLE_AMORTIZE_RECORD', // 摊销记录
  log: 'FINANCE_PAYABLE_LOG', // 日志
  export: 'FINANCE_PAYABLE_EXPORT', // 导出
}
export const financeReportPerm = 'FINANCE_REPORT'; // 资产报表
export const financeDepreciateReportPerm = 'FINANCE_DEPRECIATION_REPORT'; // 资产折旧报表
export const financeDepreciateReportBtns = {
  export: 'FINANCE_DEPRECIATION_REPORT_EXPORT', // 导出
}
export const financeUnamortizedReportPerm = 'FINANCE_UNAMORTIZED_REPORT'; // 长期摊销报表
export const financeUnamortizedReportBtns = {
  export: 'FINANCE_UNAMORTIZED_REPORT_EXPORT', // 导出
}
export const financeReceivableReportPerm = 'FINANCE_RECEIVABLE_REPORT'; // 应收租金报表
export const financeReceivableReportBtns = {
  export: 'FINANCE_RECEIVABLE_REPORT_EXPORT', // 导出
}
export const financePayableReportPerm = 'FINANCE_PAYABLE_REPORT'; // 应付租金报表
export const financePayableReportBtns = {
  export: 'FINANCE_PAYABLE_REPORT_EXPORT', // 导出
}

export function getUsefulPerms(permissions = []) {
  return new Promise((resolve: (value?: any) => any) => {
    if (!permissions || permissions.length == 0) {
      return;
    }
    // const validPerms = [
    //   controlPerm,
    //   controlDeptPerm,
    //   controlWechatPerm,
    //   fencePerm,
    //   fenceSendPerm,
    //   fenceCollectorsPerm,
    //   fenceCollectorPddPerm,
    //   fenceCollectorBpPerm,
    //   fenceCollectorSantaiPerm,
    //   fenceCollectorRefresh,
    //   orderPerm,
    //   orderVolumnPerm,
    //   orderCancelPerm,
    //   orderPickPerm,
    //   orderPCPerm,
    //   orderPushPerm,
    //   financePerm,
    //   financeBasicPerm,
    //   ...Object.values(financeBasicBtns),
    //   financeDepreciatePerm,
    //   ...Object.values(financeDepreciateBtns),
    //   financeLongtermPerm,
    //   ...Object.values(financeLongtermBtns),
    //   financeReceivablePerm,
    //   ...Object.values(financeReceivableBtns),
    //   financePayablePerm,
    //   ...Object.values(financePayableBtns),
    //   financeReportPerm,
    //   financeDepreciateReportPerm,
    //   ...Object.values(financeDepreciateReportBtns),
    //   financeUnamortizedReportPerm,
    //   ...Object.values(financeUnamortizedReportBtns),
    //   financeReceivableReportPerm,
    //   ...Object.values(financeReceivableReportBtns),
    //   financePayableReportPerm,
    //   ...Object.values(financePayableReportBtns),
    //   userPerm,
    //   userPddPerm,
    //   ...Object.values(userPddBtns),
    //   appPerm,
    //   appMenuBasePerm,
    //   ...Object.values(appMenuBaseBtns),
    //   appMenuDeptPerm,
    //   ...Object.values(appMenuDeptBtns),
    //   appPrintTplPerm,
    //   ...Object.values(appPrintTplBtns),
    // ];
    // const perms = validPerms.filter(item => permissions.indexOf(item) >= 0);
    const perms = permissions.filter(item => {
      if (item.indexOf('_') == 0 && /(?=\d)(?=.*[A-Za-z])/.test(item)) {
        return false;
      }
      if (item.indexOf('FRM') == 0 && /[\u4e00-\u9fa5]+/.test(item)) {
        return false;
      }
      return true;
    });
    if (perms.length == 0) {
      resolve(perms);
      return;
    }
    resolve(perms);
  });
}

// 判断按钮是否有权限
export function getBtnPerm(sign) {
  let page = '';
  let btn = '';
  if (sign.indexOf(':') >= 0) {
    const arr = sign.split(':')
    page = arr[0];
    btn = arr[1];
  } else {
    page = sign;
  }
  const perm = (localStorage.getItem('perms') || '').split(',');
  let currentPermObj = {};
  if (page == 'financeBasic') {
    currentPermObj = financeBasicBtns;
  } else if (page == 'financeDepreciate') {
    currentPermObj = financeDepreciateBtns;
  } else if (page == 'financeLongterm') {
    currentPermObj = financeLongtermBtns;
  } else if (page == 'financeReceivable') {
    currentPermObj = financeReceivableBtns;
  } else if (page == 'financePayable') {
    currentPermObj = financePayableBtns;
  } else if (page == 'financeDepreciateReport') {
    currentPermObj = financeDepreciateReportBtns;
  } else if (page == 'financeUnamortizedReport') {
    currentPermObj = financeUnamortizedReportBtns;
  } else if (page == 'financePayableReport') {
    currentPermObj = financePayableReportBtns;
  } else if (page == 'financeReceivableReport') {
    currentPermObj = financeReceivableReportBtns;
  } else if (page == 'appMenuBase') {
    currentPermObj = appMenuBaseBtns;
  } else if (page == 'appMenuDept') {
    currentPermObj = appMenuDeptBtns;
  } else if (page == 'appPrintTpl') {
    currentPermObj = appPrintTplBtns;
  } else if (page == 'userPdd') {
    currentPermObj = userPddBtns;
  } else {
    return perm.includes(sign);
  }
  if (!btn) {
    return Object.keys(currentPermObj).some(item => {
      return perm.includes(currentPermObj[item]);
    });
  }
  return perm.includes(currentPermObj[btn]);
}