<template>
  <div class="no-data">
    <span class="icon" :class="icon"></span>
    <span class="label">{{ message }}</span>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  icon: String,
  message: {
    type: String,
    default: '暂无数据',
  },
});

const icon = computed(() => props.icon);
const message = computed(() => props.message);

defineOptions({
  inheritAttrs: false,
});
</script>

<style lang="less" scoped>
.no-data {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 0;
  box-sizing: border-box;

  .icon {
    width: 30px;
    height: 30px;
    background: url('/img/no-data.png') no-repeat center;
    background-size: 100% 100%;
  }

  .label {
    margin: 10px 0 0;
    color: #666;
    font-size: 16px;
  }
}
</style>