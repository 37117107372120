import { createFromIconfontCN } from '@ant-design/icons-vue';

export const AliOSS_static = 'https://runninggz-assets.oss-cn-guangzhou.aliyuncs.com';
const { VITE_BASE_URL } = import.meta.env;

export const isProdServer = VITE_BASE_URL.indexOf('www.benpao56.com') >= 0;
export const isDevServer = VITE_BASE_URL.indexOf('192.168.1.100') >= 0;

export const isDevUser = ['15800011017', '17665043628', '13922252432']; // 开发者账号

export const IconFontUrl = '//at.alicdn.com/t/c/font_3296747_ibjovgnu3v.js';

export const IconFont = createFromIconfontCN({
  scriptUrl: IconFontUrl,
});

export const siteConfig = {
  title: '诚信',
  defaultTheme: '#25b864', // 默认主题色
  defaultLang: 'zh', // 默认语言
  sideWidth: 200, // 侧边栏宽度
  headerHeight: 56, // 头部高度
}

// 分页器配置
export const pageConfig = {
  pageSize: 20,
  showSizeChanger: true,
  showQuickJumper: true,
  // pageSizeOptions: [10, 20, 50, 100],
  showTotal: total => `共 ${total} 条`,
}

export const tenantId = '10000';

export const baseURL = VITE_BASE_URL // 基础路径
export const successCode = 200; // 正确的code值（返回数据）
export const notLoginCode = 401; // 登录凭证失效（返回数据）
export const notFoundCode = 404;

export const repeatRequestTime = 1000;

export const homePath = '/home';

export const checkCityMap = {
  '义乌': '金华',
  '东阳': '金华',
  '金东': '金华',
  '浦江': '金华',
  '永康': '金华',
  '兰溪': '金华',
  '武义': '金华',
  '磐安': '金华',
  '婺城': '金华',
}

export const downloadLink = {
  tmsapp: 'https://runninggz-assets.oss-cn-guangzhou.aliyuncs.com/tmsapp.prod.apk',
  tmsjm: 'https://runninggz-assets.oss-cn-guangzhou.aliyuncs.com/tmsjm.prod.apk',
  tmspc: 'https://runningbk.oss-cn-guangzhou.aliyuncs.com/TMS_setup.exe',
}